import { WorldType } from '../types/WorldType';

const PLACEHOLDER_IMAGE = 'https://placehold.co/600x400/png';
const PLACEHOLDER_GALLERY = Array(5).fill(PLACEHOLDER_IMAGE);

export const worldData: WorldType[] = [
  {
    id: 'weapons',
    name: 'Weapons',
    image: PLACEHOLDER_IMAGE,
    description: 'Forge and customize powerful weapons infused with chromatic energy. Each color offers unique combat styles and abilities.',
    galleryImages: PLACEHOLDER_GALLERY
  },
  {
    id: 'tuning',
    name: 'Tuning',
    image: PLACEHOLDER_IMAGE,
    description: 'Master the art of chromatic tuning to enhance your abilities and unlock new powers within each color spectrum.',
    galleryImages: PLACEHOLDER_GALLERY
  },
  {
    id: 'expedition',
    name: 'Expedition',
    image: PLACEHOLDER_IMAGE,
    description: 'Embark on dangerous expeditions across the frontiers, discovering ancient secrets and valuable resources.',
    galleryImages: PLACEHOLDER_GALLERY
  },
  {
    id: 'story',
    name: 'Story',
    image: PLACEHOLDER_IMAGE,
    description: 'Uncover the rich lore of the chromatic world through immersive storytelling and character-driven narratives.',
    galleryImages: PLACEHOLDER_GALLERY
  },
  {
    id: 'multiplayer',
    name: 'Play with Friends',
    image: PLACEHOLDER_IMAGE,
    description: 'Team up with allies to tackle challenging content and create your own adventures in the chromatic frontiers.',
    galleryImages: PLACEHOLDER_GALLERY
  }
]; 