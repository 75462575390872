import React, { useState } from 'react';
import ErrorBoundary from './components/common/ErrorBoundary';
import ChromaGallery from './components/chroma/ChromaGallery';
import FrontierSection from './components/frontier/FrontierSection';
import InhabitantsSection from './components/InhabitantsSection';
import FactionsSection from './components/factions/FactionsSection';
import WorldSection from './components/WorldSection';
import './App.css';

// Define a type for the valid topic names
type TopicKey = 'Chroma' | 'Frontier' | 'Inhabitants' | 'Factions' | 'World';

function App() {
  // Specify the type for useState
  const [selectedTopic, setSelectedTopic] = useState<TopicKey>('Chroma');

  // Map topics to their respective components
  const topicComponents: Record<TopicKey, React.ReactNode> = {
    Chroma: <ChromaGallery />,
    Frontier: <FrontierSection />,
    Inhabitants: <InhabitantsSection />,
    Factions: <FactionsSection />,
    World: <WorldSection />
  };

  return (
    <ErrorBoundary>
      <div className="App">
        <header className="game-header">
          <h1>Chroma: Frontier</h1>
        </header>
        
        <nav className="topic-navigation">
          {(Object.keys(topicComponents) as TopicKey[]).map((topic) => (
            <button
              key={topic}
              onClick={() => setSelectedTopic(topic)}
              className={`topic-button ${selectedTopic === topic ? 'active' : ''}`}
            >
              {topic}
            </button>
          ))}
        </nav>

        <main className="content-section">
          {topicComponents[selectedTopic]}
        </main>
      </div>
    </ErrorBoundary>
  );
}

export default App;
