import { FactionType } from '../types/FactionType';

export const factionData: FactionType[] = [
  {
    id: 'order-of-light',
    name: 'Order of Light',
    primaryColor: 'white',
    description: 'Guardians of justice and order in the Frontier.',
    galleryImages: [/* Add your image paths */]
  },
  {
    id: 'shadow-covenant',
    name: 'Shadow Covenant',
    primaryColor: 'black',
    description: 'Masters of stealth and subterfuge.',
    galleryImages: [/* Add your image paths */]
  },
  {
    id: 'twilight-sentinels',
    name: 'Twilight Sentinels',
    primaryColor: 'white',
    secondaryColor: 'black',
    description: 'Balancing light and shadow to protect the Frontier.',
    galleryImages: [/* Add your image paths */]
  },
  // Add more factions as needed
]; 