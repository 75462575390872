import React from 'react';
import FactionsGallery from './FactionsGallery';
import './FactionsSection.css';

const FactionsSection = () => {
  return (
    <section className="factions-section">
      <FactionsGallery />
    </section>
  );
};

export default FactionsSection; 