import React, { useState, useEffect } from 'react';
import { chromaData } from '../../data/chromaData';
import { ChromaType } from '../../types/ChromaType';
import './ChromaGallery.css';

const ChromaGallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);

  // Minimum swipe distance for carousel navigation
  const minSwipeDistance = 50;

  const handleNext = () => {
    if (currentIndex < chromaData.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      handleNext();
    }
    if (isRightSwipe) {
      handlePrev();
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft') handlePrev();
    if (e.key === 'ArrowRight') handleNext();
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentIndex]);

  return (
    <div 
      className="chroma-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="faction-slots">
        {chromaData[currentIndex].featuredFactions.map((faction, index) => (
          <div 
            key={faction.id}
            className="faction-slot"
          >
            <img src={faction.icon} alt={faction.name} />
            <span className="tooltip">{faction.name}</span>
          </div>
        ))}
      </div>

      <div className="chroma-carousel">
        <button 
          className="nav-button prev"
          onClick={handlePrev}
          disabled={currentIndex === 0}
        >
          ←
        </button>

        <div className="chroma-slide">
          <img 
            src={chromaData[currentIndex].image} 
            alt={chromaData[currentIndex].name} 
            className="chroma-image"
          />
          <div className="chroma-info">
            <h2>{chromaData[currentIndex].name}</h2>
            <p>{chromaData[currentIndex].description}</p>
          </div>
        </div>

        <button 
          className="nav-button next"
          onClick={handleNext}
          disabled={currentIndex === chromaData.length - 1}
        >
          →
        </button>
      </div>

      <div className="frontier-slots">
        {chromaData[currentIndex].featuredFrontiers.map((frontier, index) => (
          <div 
            key={frontier.id}
            className="frontier-slot"
          >
            <img src={frontier.icon} alt={frontier.name} />
            <span className="tooltip">{frontier.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChromaGallery; 