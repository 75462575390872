import React, { useState, useEffect, useRef } from 'react';
import { inhabitantData } from '../../data/inhabitantData';
import './InhabitantGallery.css';

const InhabitantGallery = () => {
  const [currentInhabitantIndex, setCurrentInhabitantIndex] = useState(0);
  const [currentImageIndices, setCurrentImageIndices] = useState<Record<string, number>>({});
  const listRef = useRef<HTMLDivElement>(null);
  const wheelTimeout = useRef<NodeJS.Timeout | null>(null);

  // Handle image navigation
  const navigateImage = (direction: 'prev' | 'next') => {
    const currentInhabitant = inhabitantData[currentInhabitantIndex];
    const currentImageIndex = currentImageIndices[currentInhabitant.id] || 0;
    const totalImages = currentInhabitant.galleryImages.length;

    setCurrentImageIndices({
      ...currentImageIndices,
      [currentInhabitant.id]: direction === 'next'
        ? (currentImageIndex + 1) % totalImages
        : (currentImageIndex - 1 + totalImages) % totalImages
    });
  };

  // Handle inhabitant navigation
  const navigateInhabitant = (direction: 'prev' | 'next') => {
    if (direction === 'prev' && currentInhabitantIndex > 0) {
      setCurrentInhabitantIndex(currentInhabitantIndex - 1);
    } else if (direction === 'next' && currentInhabitantIndex < inhabitantData.length - 1) {
      setCurrentInhabitantIndex(currentInhabitantIndex + 1);
    }
  };

  // Handle mouse wheel
  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      e.preventDefault();
      
      // Clear existing timeout
      if (wheelTimeout.current) {
        clearTimeout(wheelTimeout.current);
      }

      // Set new timeout to prevent rapid scrolling
      wheelTimeout.current = setTimeout(() => {
        if (e.deltaY > 0) {
          navigateInhabitant('next');
        } else {
          navigateInhabitant('prev');
        }
      }, 50);
    };

    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener('wheel', handleWheel, { passive: false });
      return () => {
        listElement.removeEventListener('wheel', handleWheel);
        if (wheelTimeout.current) {
          clearTimeout(wheelTimeout.current);
        }
      };
    }
  }, [currentInhabitantIndex]);

  return (
    <div className="inhabitant-list" ref={listRef}>
      {/* Navigation Arrows for Images */}
      <button 
        className="navigation-arrow prev"
        onClick={() => navigateImage('prev')}
      >
        ←
      </button>
      <button 
        className="navigation-arrow next"
        onClick={() => navigateImage('next')}
      >
        →
      </button>

      {/* Inhabitant Cards */}
      {inhabitantData.map((inhabitant, index) => (
        <div 
          key={inhabitant.id}
          className={`inhabitant-card ${
            index < currentInhabitantIndex - 1 ? 'offscreen-top' :
            index === currentInhabitantIndex - 1 ? 'previous' :
            index === currentInhabitantIndex ? 'focused' :
            index === currentInhabitantIndex + 1 ? 'next' :
            'offscreen-bottom'
          }`}
        >
          <div className="inhabitant-image-container">
            <img
              src={inhabitant.galleryImages[currentImageIndices[inhabitant.id] || 0]}
              alt={inhabitant.name}
              className="inhabitant-image"
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = 'https://placehold.co/600x400/png';
              }}
            />
            <div className="inhabitant-info">
              <h3 className="inhabitant-name">{inhabitant.name}</h3>
              <div className="gallery-dots">
                {inhabitant.galleryImages.map((_, imgIndex) => (
                  <span 
                    key={imgIndex} 
                    className={`dot ${imgIndex === (currentImageIndices[inhabitant.id] || 0) ? 'active' : ''}`} 
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InhabitantGallery;