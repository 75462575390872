import React from 'react';
import WorldGallery from './world/WorldGallery';
import ErrorBoundary from './common/ErrorBoundary';

const WorldSection = () => {
  return (
    <ErrorBoundary>
      <WorldGallery />
    </ErrorBoundary>
  );
};

export default WorldSection; 