import React, { useState } from 'react';
import { frontierData } from '../../data/frontierData';
import './FrontierSection.css';

const FrontierSection = () => {
  const [currentFrontierIndex, setCurrentFrontierIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const getCardClass = (index: number) => {
    const diff = index - currentFrontierIndex;
    if (diff === -2) return 'offscreen-top';
    if (diff === -1) return 'previous';
    if (diff === 0) return 'current';
    if (diff === 1) return 'next';
    if (diff === 2) return 'offscreen-bottom';
    return 'offscreen-bottom';
  };

  const handleWheel = (e: React.WheelEvent) => {
    if (e.deltaY > 0 && currentFrontierIndex < frontierData.length - 1) {
      setCurrentFrontierIndex(prev => prev + 1);
    } else if (e.deltaY < 0 && currentFrontierIndex > 0) {
      setCurrentFrontierIndex(prev => prev - 1);
    }
    setCurrentImageIndex(0);
  };

  return (
    <div className="frontier-section" onWheel={handleWheel}>
      <div className="frontier-viewport">
        {frontierData.map((frontier, index) => (
          <div 
            key={frontier.id}
            className={`frontier-card ${getCardClass(index)}`}
          >
            <img
              src={frontier.galleryImages[currentImageIndex]}
              alt={frontier.name}
              className="frontier-image"
            />
            <div className="frontier-info">
              <h2>{frontier.name}</h2>
              <p>{frontier.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FrontierSection;