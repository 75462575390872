import { InhabitantType } from '../types/InhabitantType';
import { StrixImages } from '../assets/imageImports';

const PLACEHOLDER_IMAGE = 'https://placehold.co/600x400/png';
const PLACEHOLDER_GALLERY = Array(5).fill(PLACEHOLDER_IMAGE);

export const inhabitantData: InhabitantType[] = [
  // Red Inhabitants
  {
    id: 'red-inhabitant-1',
    name: 'Flame Drake',
    image: PLACEHOLDER_IMAGE,
    chromaticType: 'red',
    description: 'A fierce drake that breathes searing flames and soars through volcanic clouds.',
    galleryImages: PLACEHOLDER_GALLERY,
    stats: { power: 8, toughness: 6, speed: 7 }
  },
  {
    id: 'red-inhabitant-2',
    name: 'Ember Warrior',
    image: PLACEHOLDER_IMAGE,
    chromaticType: 'red',
    description: 'Elite warriors who harness the power of living flame in combat.',
    galleryImages: PLACEHOLDER_GALLERY,
    stats: { power: 7, toughness: 5, speed: 6 }
  },
  {
    id: 'red-inhabitant-3',
    name: 'Magma Giant',
    image: PLACEHOLDER_IMAGE,
    chromaticType: 'red',
    description: 'Colossal beings formed from cooling magma and burning stone.',
    galleryImages: PLACEHOLDER_GALLERY,
    stats: { power: 9, toughness: 8, speed: 4 }
  },

  // Blue Inhabitants
  {
    id: 'blue-inhabitant-1',
    name: 'Frost Sphinx',
    image: PLACEHOLDER_IMAGE,
    chromaticType: 'blue',
    description: 'Ancient beings of ice and wisdom who guard forgotten knowledge.',
    galleryImages: PLACEHOLDER_GALLERY,
    stats: { power: 6, toughness: 7, speed: 6 }
  },
  {
    id: 'blue-inhabitant-2',
    name: 'Tide Caller',
    image: PLACEHOLDER_IMAGE,
    chromaticType: 'blue',
    description: 'Mystic shamans who command the waves and speak with ocean spirits.',
    galleryImages: PLACEHOLDER_GALLERY,
    stats: { power: 5, toughness: 6, speed: 7 }
  },
  {
    id: 'blue-inhabitant-3',
    name: 'Storm Drake',
    image: PLACEHOLDER_IMAGE,
    chromaticType: 'blue',
    description: 'Lightning-wreathed drakes that ride the thunderclouds.',
    galleryImages: PLACEHOLDER_GALLERY,
    stats: { power: 7, toughness: 5, speed: 8 }
  },

  // Green Inhabitants
  {
    id: 'green-inhabitant-1',
    name: 'Ancient Treant',
    image: PLACEHOLDER_IMAGE,
    chromaticType: 'green',
    description: 'Living trees that shepherd the growth of primeval forests.',
    galleryImages: PLACEHOLDER_GALLERY,
    stats: { power: 7, toughness: 9, speed: 3 }
  },
  {
    id: 'green-inhabitant-2',
    name: 'Vine Stalker',
    image: PLACEHOLDER_IMAGE,
    chromaticType: 'green',
    description: 'Stealthy predators that blend seamlessly with jungle foliage.',
    galleryImages: PLACEHOLDER_GALLERY,
    stats: { power: 6, toughness: 5, speed: 8 }
  },
  {
    id: 'green-inhabitant-3',
    name: 'Primal Behemoth',
    image: PLACEHOLDER_IMAGE,
    chromaticType: 'green',
    description: 'Massive beasts that embody natures raw strength and vitality.',
    galleryImages: PLACEHOLDER_GALLERY,
    stats: { power: 9, toughness: 7, speed: 5 }
  },

  // White Inhabitants
  {
    id: 'strix',
    name: 'Strix',
    image: StrixImages.cowboyDash,
    chromaticType: 'white',
    description: 'A mysterious owl-like being that stalks the frontier with deadly precision.',
    galleryImages: [
      StrixImages.cowboyDash,
      StrixImages.cowboyGreatscythe,
      StrixImages.cowboyRevolver,
      StrixImages.cowboyRifle,
      StrixImages.rogue
    ],
    stats: { power: 7, toughness: 5, speed: 8 }
  },
  {
    id: 'white-inhabitant-2',
    name: 'Dawn Knight',
    image: PLACEHOLDER_IMAGE,
    chromaticType: 'white',
    description: 'Holy warriors clad in armor of crystallized sunlight.',
    galleryImages: PLACEHOLDER_GALLERY,
    stats: { power: 7, toughness: 7, speed: 6 }
  },
  {
    id: 'white-inhabitant-3',
    name: 'Celestial Guardian',
    image: PLACEHOLDER_IMAGE,
    chromaticType: 'white',
    description: 'Ancient constructs powered by divine energy that protect sacred sites.',
    galleryImages: PLACEHOLDER_GALLERY,
    stats: { power: 8, toughness: 8, speed: 4 }
  },

  // Black Inhabitants
  {
    id: 'black-inhabitant-1',
    name: 'Shadow Wraith',
    image: PLACEHOLDER_IMAGE,
    chromaticType: 'black',
    description: 'Ethereal beings that feed on fear and darkness.',
    galleryImages: PLACEHOLDER_GALLERY,
    stats: { power: 7, toughness: 5, speed: 7 }
  },
  {
    id: 'black-inhabitant-2',
    name: 'Plague Harbinger',
    image: PLACEHOLDER_IMAGE,
    chromaticType: 'black',
    description: 'Corrupted shamans who wield diseases as weapons.',
    galleryImages: PLACEHOLDER_GALLERY,
    stats: { power: 5, toughness: 6, speed: 6 }
  },
  {
    id: 'black-inhabitant-3',
    name: 'Void Leviathan',
    image: PLACEHOLDER_IMAGE,
    chromaticType: 'black',
    description: 'Massive creatures born from the deepest darkness between worlds.',
    galleryImages: PLACEHOLDER_GALLERY,
    stats: { power: 9, toughness: 7, speed: 5 }
  }
]; 