// Define a placeholder image URL
const PLACEHOLDER_IMAGE = 'https://placehold.co/600x400/png';

export const ChromaticImages = {
  main: {
    red: PLACEHOLDER_IMAGE,
    blue: PLACEHOLDER_IMAGE,
    green: PLACEHOLDER_IMAGE,
    white: PLACEHOLDER_IMAGE,
    black: PLACEHOLDER_IMAGE,
  },
  creatures: {
    red: Array(5).fill(PLACEHOLDER_IMAGE),
    blue: Array(5).fill(PLACEHOLDER_IMAGE),
    green: Array(5).fill(PLACEHOLDER_IMAGE),
    white: Array(5).fill(PLACEHOLDER_IMAGE),
    black: Array(5).fill(PLACEHOLDER_IMAGE),
  },
  frontiers: {
    red: Array(3).fill(PLACEHOLDER_IMAGE),
    blue: Array(3).fill(PLACEHOLDER_IMAGE),
    green: Array(3).fill(PLACEHOLDER_IMAGE),
    white: Array(3).fill(PLACEHOLDER_IMAGE),
    black: Array(3).fill(PLACEHOLDER_IMAGE),
  }
};

// Strix Images
export const StrixImages = {
  cowboyDash: require('./chromatic/creatures/Strix/StrixCowboyDash.png'),
  cowboyGreatscythe: require('./chromatic/creatures/Strix/StrixCowboyGreatscythe.webp'),
  cowboyRevolver: require('./chromatic/creatures/Strix/StrixCowboyRevolver.webp'),
  cowboyRifle: require('./chromatic/creatures/Strix/StrixCowboyRifle.webp'),
  rogue: require('./chromatic/creatures/Strix/StrixRogue.webp'),
};

// Frontier Images
export const FrontierImages = {
  theLoom: {
    main: require('./chromatic/Frontiers/The Loom/Loom1.webp'),
    gallery: [
      require('./chromatic/Frontiers/The Loom/Loom1.webp'),
      require('./chromatic/Frontiers/The Loom/Loom2.webp'),
      require('./chromatic/Frontiers/The Loom/Loom3.webp'),
      require('./chromatic/Frontiers/The Loom/Loom4.webp'),
      require('./chromatic/Frontiers/The Loom/Loom5.webp'),
    ]
  }
}; 