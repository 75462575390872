import React, { useState, useEffect } from 'react';
import { worldData } from '../../data/worldData';
import './WorldGallery.css';

const WorldGallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-advance the gallery every 5 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % worldData.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const handlePrevious = () => {
    setCurrentIndex((prev) => 
      prev === 0 ? worldData.length - 1 : prev - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prev) => 
      (prev + 1) % worldData.length
    );
  };

  return (
    <div className="world-gallery">
      <div className="world-gallery-container">
        <button 
          className="nav-button prev" 
          onClick={handlePrevious}
          aria-label="Previous mechanic"
        >
          ←
        </button>

        <div className="main-image-container">
          <img
            src={worldData[currentIndex].image}
            alt={worldData[currentIndex].name}
            className="main-image"
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = 'https://placehold.co/600x400/png';
            }}
          />
          <div className="mechanic-info">
            <h2 className="mechanic-name">{worldData[currentIndex].name}</h2>
            <p className="mechanic-description">{worldData[currentIndex].description}</p>
          </div>
        </div>

        <button 
          className="nav-button next" 
          onClick={handleNext}
          aria-label="Next mechanic"
        >
          →
        </button>
      </div>

      <div className="gallery-dots">
        {worldData.map((_, index) => (
          <button
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
            aria-label={`Go to ${worldData[index].name}`}
          />
        ))}
      </div>
    </div>
  );
};

export default WorldGallery; 