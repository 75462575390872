import React, { useState, useRef } from 'react';
import { factionData } from '../../data/factionData';
import './FactionsGallery.css';

const FactionsGallery = () => {
  const [currentFactionIndex, setCurrentFactionIndex] = useState(0);
  const [currentImageIndices, setCurrentImageIndices] = useState<Record<string, number>>({});
  const wheelTimeout = useRef<NodeJS.Timeout | null>(null);

  const navigateImage = (direction: 'prev' | 'next') => {
    const currentFaction = factionData[currentFactionIndex];
    const currentImageIndex = currentImageIndices[currentFaction.id] || 0;
    const totalImages = currentFaction.galleryImages.length;

    setCurrentImageIndices({
      ...currentImageIndices,
      [currentFaction.id]: direction === 'next'
        ? (currentImageIndex + 1) % totalImages
        : (currentImageIndex - 1 + totalImages) % totalImages
    });
  };

  const handleWheel = (e: React.WheelEvent) => {
    e.preventDefault();
    
    if (wheelTimeout.current) {
      clearTimeout(wheelTimeout.current);
    }

    wheelTimeout.current = setTimeout(() => {
      if (e.deltaY > 0 && currentFactionIndex < factionData.length - 1) {
        setCurrentFactionIndex(currentFactionIndex + 1);
      } else if (e.deltaY < 0 && currentFactionIndex > 0) {
        setCurrentFactionIndex(currentFactionIndex - 1);
      }
    }, 50);
  };

  return (
    <div 
      className="factions-list"
      onWheel={handleWheel}
    >
      <button 
        className="navigation-arrow prev"
        onClick={() => navigateImage('prev')}
      >
        ←
      </button>
      <button 
        className="navigation-arrow next"
        onClick={() => navigateImage('next')}
      >
        →
      </button>

      {factionData.map((faction, index) => (
        <div 
          key={faction.id}
          className={`faction-card ${
            index < currentFactionIndex - 1 ? 'offscreen-top' :
            index === currentFactionIndex - 1 ? 'previous' :
            index === currentFactionIndex ? 'focused' :
            index === currentFactionIndex + 1 ? 'next' :
            'offscreen-bottom'
          }`}
        >
          <div className="faction-image-container">
            <img
              src={faction.galleryImages[currentImageIndices[faction.id] || 0]}
              alt={faction.name}
              className="faction-image"
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = 'https://placehold.co/600x400/png';
              }}
            />
            <div className="faction-info">
              <h3 className="faction-name">{faction.name}</h3>
              <div className="gallery-dots">
                {faction.galleryImages.map((_, imgIndex) => (
                  <span 
                    key={imgIndex} 
                    className={`dot ${imgIndex === (currentImageIndices[faction.id] || 0) ? 'active' : ''}`} 
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FactionsGallery;