import { ChromaType } from '../types/ChromaType';

export const chromaData: ChromaType[] = [
  {
    id: 'white-chroma',
    name: 'White Chroma',
    description: 'The essence of light and order. White Chroma represents unity, protection, and healing.',
    chromaticType: 'white' as const,
    image: 'https://placehold.co/600x400/ffffff/000000.png',
    featuredFactions: [
      {
        id: 'order-of-light',
        name: 'Order of Light',
        icon: 'https://placehold.co/100x100/ffffff/000000.png'
      },
      {
        id: 'guardian-alliance',
        name: 'Guardian Alliance',
        icon: 'https://placehold.co/100x100/ffffff/000000.png'
      },
      {
        id: 'divine-council',
        name: 'Divine Council',
        icon: 'https://placehold.co/100x100/ffffff/000000.png'
      },
      {
        id: 'radiant-dawn',
        name: 'Radiant Dawn',
        icon: 'https://placehold.co/100x100/ffffff/000000.png'
      },
      {
        id: 'celestial-court',
        name: 'Celestial Court',
        icon: 'https://placehold.co/100x100/ffffff/000000.png'
      }
    ],
    featuredFrontiers: [
      {
        id: 'the-loom',
        name: 'The Loom',
        icon: 'https://placehold.co/100x100/ffffff/000000.png'
      },
      {
        id: 'cathedral',
        name: 'Cathedral',
        icon: 'https://placehold.co/100x100/ffffff/000000.png'
      },
      {
        id: 'sanctuary',
        name: 'Sanctuary',
        icon: 'https://placehold.co/100x100/ffffff/000000.png'
      },
      {
        id: 'monastery',
        name: 'Monastery',
        icon: 'https://placehold.co/100x100/ffffff/000000.png'
      },
      {
        id: 'holy-citadel',
        name: 'Holy Citadel',
        icon: 'https://placehold.co/100x100/ffffff/000000.png'
      }
    ]
  },
  {
    id: 'blue-chroma',
    name: 'Blue Chroma',
    description: 'The essence of knowledge and manipulation. Blue Chroma embodies wisdom, control, and foresight.',
    chromaticType: 'blue' as const,
    image: 'https://placehold.co/600x400/0000ff/ffffff.png',
    featuredFactions: [
      {
        id: 'arcane-council',
        name: 'Arcane Council',
        icon: 'https://placehold.co/100x100/0000ff/ffffff.png'
      },
      {
        id: 'mystic-order',
        name: 'Mystic Order',
        icon: 'https://placehold.co/100x100/0000ff/ffffff.png'
      },
      {
        id: 'thought-collective',
        name: 'Thought Collective',
        icon: 'https://placehold.co/100x100/0000ff/ffffff.png'
      },
      {
        id: 'mind-weavers',
        name: 'Mind Weavers',
        icon: 'https://placehold.co/100x100/0000ff/ffffff.png'
      },
      {
        id: 'time-keepers',
        name: 'Time Keepers',
        icon: 'https://placehold.co/100x100/0000ff/ffffff.png'
      }
    ],
    featuredFrontiers: [
      {
        id: 'academy',
        name: 'Academy',
        icon: 'https://placehold.co/100x100/0000ff/ffffff.png'
      },
      {
        id: 'library',
        name: 'Library',
        icon: 'https://placehold.co/100x100/0000ff/ffffff.png'
      },
      {
        id: 'observatory',
        name: 'Observatory',
        icon: 'https://placehold.co/100x100/0000ff/ffffff.png'
      },
      {
        id: 'archives',
        name: 'Archives',
        icon: 'https://placehold.co/100x100/0000ff/ffffff.png'
      },
      {
        id: 'study',
        name: 'Study',
        icon: 'https://placehold.co/100x100/0000ff/ffffff.png'
      }
    ]
  }
]; 