import React from 'react';
import InhabitantGallery from './inhabitants/InhabitantGallery';
import './InhabitantsSection.css';

const InhabitantsSection = () => {
  return (
    <section className="inhabitants-section">
      <InhabitantGallery />
    </section>
  );
};

export default InhabitantsSection; 