import { FrontierType } from '../types/FrontierType';

export const frontierData: FrontierType[] = [
  // White Frontiers
  {
    id: 'the-loom',
    name: 'The Loom',
    description: 'The central nexus where all Chromas interweave, creating the fabric of reality itself.',
    image: 'https://placehold.co/600x400/ffffff/000000.png',
    galleryImages: [
      'https://placehold.co/600x400/ffffff/000000.png',
      'https://placehold.co/600x400/ffffff/000000.png',
      'https://placehold.co/600x400/ffffff/000000.png'
    ],
    associatedChroma: 'white'
  },
  {
    id: 'cathedral',
    name: 'Cathedral of Light',
    description: 'A bastion of white Chroma, where holy magic and divine protection reign supreme.',
    image: 'https://placehold.co/600x400/ffffff/000000.png',
    galleryImages: [
      'https://placehold.co/600x400/ffffff/000000.png',
      'https://placehold.co/600x400/ffffff/000000.png',
      'https://placehold.co/600x400/ffffff/000000.png'
    ],
    associatedChroma: 'white'
  },
  {
    id: 'sanctuary',
    name: 'Sanctuary',
    description: 'A peaceful haven where white Chroma manifests as healing and protection.',
    image: 'https://placehold.co/600x400/ffffff/000000.png',
    galleryImages: [
      'https://placehold.co/600x400/ffffff/000000.png',
      'https://placehold.co/600x400/ffffff/000000.png',
      'https://placehold.co/600x400/ffffff/000000.png'
    ],
    associatedChroma: 'white'
  },

  // Blue Frontiers
  {
    id: 'academy',
    name: 'Academy of Mysteries',
    description: 'A center of learning where blue Chroma fuels the pursuit of knowledge and mastery.',
    image: 'https://placehold.co/600x400/0000ff/ffffff.png',
    galleryImages: [
      'https://placehold.co/600x400/0000ff/ffffff.png',
      'https://placehold.co/600x400/0000ff/ffffff.png',
      'https://placehold.co/600x400/0000ff/ffffff.png'
    ],
    associatedChroma: 'blue'
  },
  {
    id: 'observatory',
    name: 'Celestial Observatory',
    description: 'Where blue Chroma enables the study of the cosmos and manipulation of reality.',
    image: 'https://placehold.co/600x400/0000ff/ffffff.png',
    galleryImages: [
      'https://placehold.co/600x400/0000ff/ffffff.png',
      'https://placehold.co/600x400/0000ff/ffffff.png',
      'https://placehold.co/600x400/0000ff/ffffff.png'
    ],
    associatedChroma: 'blue'
  },
  {
    id: 'library',
    name: 'Grand Library',
    description: 'An infinite repository of knowledge, powered by blue Chroma.',
    image: 'https://placehold.co/600x400/0000ff/ffffff.png',
    galleryImages: [
      'https://placehold.co/600x400/0000ff/ffffff.png',
      'https://placehold.co/600x400/0000ff/ffffff.png',
      'https://placehold.co/600x400/0000ff/ffffff.png'
    ],
    associatedChroma: 'blue'
  },

  // Black Frontiers
  {
    id: 'necropolis',
    name: 'Necropolis',
    description: 'A city of the dead where black Chroma manifests as darkness and decay.',
    image: 'https://placehold.co/600x400/000000/ffffff.png',
    galleryImages: [
      'https://placehold.co/600x400/000000/ffffff.png',
      'https://placehold.co/600x400/000000/ffffff.png',
      'https://placehold.co/600x400/000000/ffffff.png'
    ],
    associatedChroma: 'black'
  },
  {
    id: 'shadowkeep',
    name: 'Shadowkeep',
    description: 'A fortress of darkness where black Chroma empowers ambition and conquest.',
    image: 'https://placehold.co/600x400/000000/ffffff.png',
    galleryImages: [
      'https://placehold.co/600x400/000000/ffffff.png',
      'https://placehold.co/600x400/000000/ffffff.png',
      'https://placehold.co/600x400/000000/ffffff.png'
    ],
    associatedChroma: 'black'
  },
  {
    id: 'undercity',
    name: 'Undercity',
    description: 'A sprawling underground metropolis thriving on black Chroma.',
    image: 'https://placehold.co/600x400/000000/ffffff.png',
    galleryImages: [
      'https://placehold.co/600x400/000000/ffffff.png',
      'https://placehold.co/600x400/000000/ffffff.png',
      'https://placehold.co/600x400/000000/ffffff.png'
    ],
    associatedChroma: 'black'
  },

  // Red Frontiers
  {
    id: 'forge',
    name: 'The Great Forge',
    description: 'Where red Chroma fuels creation through destruction.',
    image: 'https://placehold.co/600x400/ff0000/ffffff.png',
    galleryImages: [
      'https://placehold.co/600x400/ff0000/ffffff.png',
      'https://placehold.co/600x400/ff0000/ffffff.png',
      'https://placehold.co/600x400/ff0000/ffffff.png'
    ],
    associatedChroma: 'red'
  },
  {
    id: 'battlefield',
    name: 'Eternal Battlefield',
    description: 'A realm of constant conflict, powered by red Chroma.',
    image: 'https://placehold.co/600x400/ff0000/ffffff.png',
    galleryImages: [
      'https://placehold.co/600x400/ff0000/ffffff.png',
      'https://placehold.co/600x400/ff0000/ffffff.png',
      'https://placehold.co/600x400/ff0000/ffffff.png'
    ],
    associatedChroma: 'red'
  },
  {
    id: 'volcano',
    name: 'Living Volcano',
    description: 'A volatile landscape where red Chroma manifests as pure chaos.',
    image: 'https://placehold.co/600x400/ff0000/ffffff.png',
    galleryImages: [
      'https://placehold.co/600x400/ff0000/ffffff.png',
      'https://placehold.co/600x400/ff0000/ffffff.png',
      'https://placehold.co/600x400/ff0000/ffffff.png'
    ],
    associatedChroma: 'red'
  },

  // Green Frontiers
  {
    id: 'forest',
    name: 'Primordial Forest',
    description: 'An ancient woodland pulsing with green Chroma.',
    image: 'https://placehold.co/600x400/00ff00/000000.png',
    galleryImages: [
      'https://placehold.co/600x400/00ff00/000000.png',
      'https://placehold.co/600x400/00ff00/000000.png',
      'https://placehold.co/600x400/00ff00/000000.png'
    ],
    associatedChroma: 'green'
  },
  {
    id: 'grove',
    name: 'Sacred Grove',
    description: 'A mystical sanctuary where green Chroma nurtures life.',
    image: 'https://placehold.co/600x400/00ff00/000000.png',
    galleryImages: [
      'https://placehold.co/600x400/00ff00/000000.png',
      'https://placehold.co/600x400/00ff00/000000.png',
      'https://placehold.co/600x400/00ff00/000000.png'
    ],
    associatedChroma: 'green'
  },
  {
    id: 'wilderness',
    name: 'Untamed Wilderness',
    description: 'A vast expanse where green Chroma runs wild and free.',
    image: 'https://placehold.co/600x400/00ff00/000000.png',
    galleryImages: [
      'https://placehold.co/600x400/00ff00/000000.png',
      'https://placehold.co/600x400/00ff00/000000.png',
      'https://placehold.co/600x400/00ff00/000000.png'
    ],
    associatedChroma: 'green'
  }
]; 